import React, { useEffect, useState, useRef } from "react";
import PictureModal from "./PictureModal";

export default function PictureSlider({
  imageData,
  campaignId,
  keyPressed,
  logOut,
  hideSortedOut,
}) {
  const [imageId, setImageId] = useState(null);
  const [sorted_out, setsorted_out] = useState(false);
  const [history, setHistory] = useState(null);

  const historyRef = useRef(history);
  const campaignIdRef = useRef(campaignId);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [spaceKeyActive, setSpaceKeyActive] = useState(false);

  useEffect(() => {
    historyRef.current = history;
    campaignIdRef.current = campaignId;

    if (history != null && history.length === 0 && imageId != null) {
      //      console.log("history Effect", history, campaignId);
      setHistory([
        ...history,
        {
          log_id: history.length,
          currently_displayed: imageData[imageId].screenshot_id,
          current_unix_timestamp: Date.now(),
        },
      ]);
    }
  }, [history, campaignId]);

  useEffect(() => {
    function handleResize() {
      try {
        const containerx = document.getElementById(
          "pictureslider-image-slider-content"
        );
        const children = containerx.children;
        for (let i = 0; i < children.length; i++) {
          //setzte die breite des Elements auf die Breite des enthaltenen IMG ELements
          children[i].style.width = children[i].children[0].offsetWidth + "px";
        }
      } catch (e) {
        console.log(e);
      }
    }

    window.addEventListener("resize", handleResize);

    // Aufrufen der handleResize Funktion direkt, um die Größe beim ersten Rendern zu aktualisieren
    handleResize();

    // Event Listener bei Unmount entfernen
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      try {
        const containerx = document.getElementById(
          "pictureslider-image-slider-content"
        );
        const children = containerx.children;
        for (let i = 0; i < children.length; i++) {
          //setzte die breite des Elements auf die Breite des enthaltenen IMG ELements
          children[i].style.width = children[i].children[0].offsetWidth + "px";
        }
        //scrollToImage();
      } catch (e) {
        console.log(e);
      }
    }, 500);
    return () => clearInterval(timer);
  }, []);

  //

  const saveLog = async () => {
    // console.log("saveLog", historyRef.current, campaignIdRef.current);
    if (historyRef.current.length > 0) {
      try {
        //send Daten an Server
        fetch("https://process-server.videobomb.de/add_screenshots_log", {
          method: "POST",
          body: JSON.stringify({
            camp_id: campaignIdRef.current,
            history: historyRef.current,
          }),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then(
            (result) => {
              if (result.error) {
                if (result?.message === "Signature has expired") logOut();
              }
              //console.log("add_screenshots_log", result);
              if (result?.last_used_log_id !== undefined) {
                setHistory(
                  historyRef.current.filter(
                    (item) => item.log_id > result.last_used_log_id
                  )
                );
              }
            },
            (error) => {
              console.log(error);
            }
          );
      } catch (e) {
        console.log(e);
      }
    }
  };
  //

  useEffect(() => {
    const timer2 = setInterval(() => {
      saveLog();
    }, 5000);
    return () => clearInterval(timer2);
  }, []);

  const scrollToImage = () => {
    var container = document.getElementById(
      "pictureslider-image-slider-content"
    );
    var content = document.getElementsByClassName("image-selected")[0];
    var viewportWidth = window.innerWidth;
    var contentWidth = content.offsetWidth;

    // Scrollen Sie horizontal zur Mitte des Inhalts
    container.scrollLeft =
      content.offsetLeft - viewportWidth / 2 + contentWidth / 2;

    window.localStorage.setItem(
      "screenshot_id",
      imageData[imageId].screenshot_id
    );
  };

  useEffect(() => {
    if (imageId != null) {
      if (history != null) {
        setHistory([
          ...history,
          {
            log_id: history.length,
            currently_displayed: imageData[imageId].screenshot_id,
            current_unix_timestamp: Date.now(),
          },
        ]);
      } else {
        setHistory([
          {
            log_id: 0,
            currently_displayed: imageData[imageId].screenshot_id,
            current_unix_timestamp: Date.now(),
          },
        ]);
      }
      scrollToImage();
    }
  }, [imageId]);

  useEffect(() => {
    if (imageData && imageData.length > 0) {
      setImageId(0);
    }
  }, [imageData]);

  const getImageElements = (start, end) => {
    const array = [];

    if (start === 0) {
      array.push(
        <div key={start + 100} className="slider-image-container">
          <img
            src={process.env.PUBLIC_URL + "/dummy.png"}
            alt="small"
            className={""}
          />
        </div>
      );
      array.push(
        <div key={start + 101} className="slider-image-container">
          <img
            src={process.env.PUBLIC_URL + "/dummy.png"}
            alt="small"
            className={""}
          />
        </div>
      );
    }

    for (let i = start; i < end; i++) {
      if (!imageData?.[i]) continue;
      if (hideSortedOut && imageData?.[i]?.sorted_out === 1) continue;
      array.push(
        <div
          key={i}
          onClick={() => {
            if (spaceKeyActive) return;
            setImageId(i);
          }}
          className={
            imageId === i
              ? "image-selected slider-image-container"
              : "not-active-image slider-image-container"
          }
        >
          <img
            src={imageData?.[i].screenshot_url}
            alt="small"
            className={imageData?.[i]?.sorted_out === 1 ? "sorted-out" : ""}
          />
        </div>
      );
    }

    if (end === imageData.length) {
      array.push(
        <div key={start + 104} className="slider-image-container">
          <img
            src={process.env.PUBLIC_URL + "/dummy.png"}
            alt="small"
            className={""}
          />
        </div>
      );
      array.push(
        <div key={start + 105} className="slider-image-container">
          <img
            src={process.env.PUBLIC_URL + "/dummy.png"}
            alt="small"
            className={""}
          />
        </div>
      );
    }
    return array;
  };

  const createImageOutput = () => {
    if (imageData && imageData.length > 0) {
      if (imageId - 2 <= 0 && imageId + 5 < imageData.length) {
        return getImageElements(0, 5);
      }

      if (imageId - 2 <= 0 && imageId + 5 > imageData.length) {
        return getImageElements(0, imageData.length);
      }

      if (imageId + 1 >= imageData.length && imageId - 5 >= 0) {
        return getImageElements(imageId - 4, imageData.length);
      }
      if (imageId + 2 >= imageData.length && imageId - 4 >= 0) {
        return getImageElements(imageId - 3, imageData.length);
      }
      if (imageId + 3 >= imageData.length && imageId - 3 >= 0) {
        return getImageElements(imageId - 2, imageData.length);
      }

      return getImageElements(imageId - 2, imageId + 3);
    }
    return [];
  };

  const imageAusgabe = createImageOutput();

  const showNextPicture = () => {
    if (imageId < imageData.length - 1) {
      setImageId(imageId + 1);
    } else {
      setImageId(0);
    }
  };

  const sortedOutApi = (sortedOut) => {
    fetch("https://process-server.videobomb.de/set_screenshot_sorted_out", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        screenshot_id: imageData[imageId].screenshot_id,
        lead_id: imageData[imageId].lead_id,
        camp_id: campaignId,
        sorted_out: imageData[imageId]?.sorted_out === 1 ? 0 : 1,
        sorted_out_reason: sortedOut,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data?.message === "Signature has expired") logOut();
        }
        console.log("Success:", data);
        setsorted_out(!sorted_out);

        imageData[imageId].sorted_out = data.sorted_out;
        showNextPicture();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    console.log("Key Pressed Picture Slider " + new Date().getTime());
    if (
      keyPressed === null ||
      (spaceKeyActive &&
        !(
          keyPressed.key === "c" ||
          keyPressed.key === "x" ||
          keyPressed.key === "v"
        ))
    )
      return;

    if (keyPressed.key === "ArrowLeft" || keyPressed.keyCode === 37) {
      if (imageId > 0) {
        setImageId(imageId - 1);
      } else {
        setImageId(imageData.length - 1);
      }
      return;
    }
    if (keyPressed.key === "ArrowRight" || keyPressed.keyCode === 39) {
      showNextPicture();
      return;
    }

    if (keyPressed.key === "space" || keyPressed.keyCode === 32) {
      if (imageData[imageId].sorted_out === 1) {
        sortedOutApi("");
        return;
      }
      setSpaceKeyActive(true);

      return;
    }

    if (
      !keyPressed.ctrlKey &&
      (keyPressed.key === "c" ||
        keyPressed.key === "x" ||
        keyPressed.key === "v")
    ) {
      sortedOutApi(
        keyPressed.key === "c"
          ? "cookie"
          : keyPressed.key === "x"
          ? "popup"
          : "wrong_image"
      );
      setSpaceKeyActive(false);
      return;
    }
    if (keyPressed.key === "b") {
      try {
        document.getElementById("url-button").click();
      } catch {}
    }
  }, [keyPressed]);

  if (imageData?.[imageId] === undefined) {
    if (imageData?.[imageId - 1]) {
      setImageId(imageId - 1);
    } else {
      setImageId(0);
    }
    return <div>Loading...</div>;
  }

  return (
    <div className="pictureslider" id="pictureslider">
      <div className="pictureslider-big">
        <div
          className={
            imageData[imageId]?.sorted_out === 1
              ? "pictureslider-big-image red-border"
              : "pictureslider-big-image"
          }
          style={{ position: "relative" }}
        >
          {imageId != null && (
            <img
              onClick={openModal}
              src={
                imageData[imageId]?.screenshot_compressed_url
                  ? imageData[imageId]?.screenshot_compressed_url
                  : imageData[imageId]?.screenshot_big_url
              }
              alt="big"
            />
          )}
          <div
            style={{
              position: "absolute",
              bottom: "-20px",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              gap: "25px",
            }}
          >
            <button
              style={{ fontSize: "24px" }}
              onClick={() => {
                sortedOutApi("popup");
              }}
              onKeyDown={(e) => {
                if (
                  e.key === "ArrowDown" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "x" ||
                  e.key === "v" ||
                  e.key === "c"
                )
                  e.preventDefault();
              }}
              onKeyUp={(e) => {
                if (
                  e.key === "ArrowDown" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "x" ||
                  e.key === "v" ||
                  e.key === "c"
                )
                  e.preventDefault();
              }}
            >
              PopUp (X)
            </button>{" "}
            <button
              style={{ fontSize: "24px" }}
              onClick={() => {
                sortedOutApi("cookie");
              }}
              onKeyDown={(e) => {
                if (
                  e.key === "ArrowDown" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "x" ||
                  e.key === "v" ||
                  e.key === "c"
                )
                  e.preventDefault();
              }}
              onKeyUp={(e) => {
                if (
                  e.key === "ArrowDown" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "x" ||
                  e.key === "v" ||
                  e.key === "c"
                )
                  e.preventDefault();
              }}
            >
              Cookie (C)
            </button>{" "}
            <button
              style={{ fontSize: "24px" }}
              onClick={() => {
                sortedOutApi("wrong_image");
              }}
              onKeyDown={(e) => {
                if (
                  e.key === "ArrowDown" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "x" ||
                  e.key === "v" ||
                  e.key === "c"
                )
                  e.preventDefault();
              }}
              onKeyUp={(e) => {
                if (
                  e.key === "ArrowDown" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "x" ||
                  e.key === "v" ||
                  e.key === "c"
                )
                  e.preventDefault();
              }}
            >
              Wrong Image (V)
            </button>{" "}
          </div>
        </div>

        <div className="pictureslider-big-image-info">
          <div>
            <div
              onClick={() => {
                if (spaceKeyActive) return;
                const id = prompt(
                  "Zu welchem Bild möchten Sie springen?",
                  imageId + 1
                );
                if (
                  id != null &&
                  id !== "" &&
                  !isNaN(id) &&
                  id >= 1 &&
                  id <= imageData.length
                ) {
                  setImageId(parseInt(id) - 1);
                }
              }}
            >
              {" "}
              {imageId + 1} / {imageData.length}
            </div>
            <div>Lead ID: {imageData[imageId].lead_id}</div>
            <div>Screenshot Id: {imageData[imageId].screenshot_id}</div>
            <a
              style={{ cursor: "pointer" }}
              id="url-button"
              href={imageData[imageId].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              URL: {imageData[imageId].url}
            </a>
          </div>
          {imageId != null && (
            <div style={{ marginTop: "25px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 0px",
                  fontWeight: "bold",
                }}
              >
                <div> Datum</div>
                <div>Dauer</div>
              </div>
              {imageData[imageId].screenshot_access_history.map(
                (item, index) => {
                  let date = new Date(item.screenshot_accessed);

                  let options = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  };

                  let formattedDate = date.toLocaleString("de-DE", options);
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div> {formattedDate} </div>
                      <div> {item.screenshot_duration} sec</div>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className="pictureslider-image-slider"
        id="pictureslider-image-slider"
        style={{ position: "relative" }}
      >
        <div
          className="pictureslider-image-slider-content"
          id="pictureslider-image-slider-content"
        >
          {imageAusgabe}
        </div>
      </div>
      <PictureModal
        isOpen={isOpen}
        imgSrc={imageData?.[imageId]?.screenshot_big_url}
        onClose={closeModal}
        imgSmall={imageData?.[imageId]?.screenshot_compressed_url}
      />
      {spaceKeyActive && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              fontWeight: "800",
              color: "green",
              fontSize: "24px",
              padding: "20px",
              maxWidth: "250px",
              maxHeight: "150px",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            {" "}
            Choose a Reason: <br />
            c = Cookie <br />x = Popup <br /> v = WrongImage
          </div>
        </div>
      )}
    </div>
  );
}
