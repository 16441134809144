import React, { useEffect, useState } from "react";

export default function PictureSliderSortedOutBottom({
  imageData,

  setBigImageUrl,
  keyPressed,
}) {
  const [imageId, setImageId] = useState(null);

  useEffect(() => {
    if (imageId != null) {
      var container = document.getElementById(
        "pictureslider-image-slider-content-bottom"
      );
      var content = document.getElementsByClassName("image-selected-bottom")[0];
      var viewportWidth = window.innerWidth;
      var contentWidth = content.offsetWidth;

      // Scrollen Sie horizontal zur Mitte des Inhalts
      container.scrollLeft =
        content.offsetLeft - viewportWidth / 2 + contentWidth / 2;
    }
  }, [imageId]);

  useEffect(() => {
    if (imageData && imageData.length > 0) {
      setImageId(0);
    }
  }, [imageData]);

  useEffect(() => {
    if (keyPressed === null) return;
    if (keyPressed.key === "ArrowRight") {
      if (imageId + 1 < imageData.length) {
        setImageId(imageId + 1);

        setBigImageUrl({
          vc_screenshot_path_compressed:
            imageData?.[imageId + 1]?.vc_screenshot_path_compressed,
          vc_screenshot_download_url:
            imageData?.[imageId + 1]?.vc_screenshot_download_url,
        });
      }
    }
    if (keyPressed.key === "ArrowLeft") {
      if (imageId - 1 >= 0) {
        setImageId(imageId - 1);

        setBigImageUrl({
          vc_screenshot_path_compressed:
            imageData?.[imageId - 1]?.vc_screenshot_path_compressed,
          vc_screenshot_download_url:
            imageData?.[imageId - 1]?.vc_screenshot_download_url,
        });
      }
    }
  }, [keyPressed]);

  const getImageElements = (start, end) => {
    const array = [];

    for (let i = start; i < end; i++) {
      if (!imageData?.[i]) continue;
      array.push(
        <div
          key={i}
          onClick={() => {
            setImageId(i);

            setBigImageUrl({
              vc_screenshot_path_compressed:
                imageData?.[i]?.vc_screenshot_path_compressed,
              vc_screenshot_download_url:
                imageData?.[i]?.vc_screenshot_download_url,
            });
          }}
          className={
            imageId === i
              ? "image-selected-bottom slider-image-container"
              : "not-active-image slider-image-container"
          }
          style={{ height: "100%", display: "grid", placeItems: "center" }}
        >
          <img
            src={
              imageData?.[i].vc_screenshot_download_min_url
                ? imageData?.[i].vc_screenshot_download_min_url
                : imageData?.[i].vc_screenshot_path_compressed
                ? imageData?.[i].vc_screenshot_path_compressed
                : imageData?.[i].vc_screenshot_download_url
            }
            alt="small"
            className=""
            style={{
              maxHeight: "100%",
              maxWidth: "100%",

              objectFit: "scale-down",
            }}
          />
        </div>
      );
    }

    return array;
  };

  const createImageOutput = () => {
    if (imageData && imageData.length > 0) {
      if (imageId - 2 <= 0 && imageId + 5 < imageData.length) {
        return getImageElements(0, 5);
      }

      if (imageId - 2 <= 0 && imageId + 5 > imageData.length) {
        return getImageElements(0, imageData.length);
      }

      if (imageId + 1 >= imageData.length && imageId - 5 >= 0) {
        return getImageElements(imageId - 4, imageData.length);
      }
      if (imageId + 2 >= imageData.length && imageId - 4 >= 0) {
        return getImageElements(imageId - 3, imageData.length);
      }
      if (imageId + 3 >= imageData.length && imageId - 3 >= 0) {
        return getImageElements(imageId - 2, imageData.length);
      }

      return getImageElements(imageId - 2, imageId + 3);
    }
    return [];
  };

  const imageAusgabe = createImageOutput();

  return (
    <div id="pictureslider" style={{ height: "100%" }}>
      <div
        className=""
        id="pictureslider-image-slider-bottom"
        style={{ height: "100%" }}
      >
        <div
          className=""
          id="pictureslider-image-slider-content-bottom"
          style={{
            display: "flex",
            overflow: "auto",
            padding: "10px 10px",
            height: "calc( 100% - 20px)",
          }}
        >
          {" "}
          {imageAusgabe}
        </div>
      </div>
    </div>
  );
}
