
import { useEffect } from 'react';
import './App.css';
import TabComponent from './TabComponent';
import { useState } from 'react';
import LoginPage from './Login';

function App() {

  const tabs = [{ name: "Slider", }, { name: "Sorted Out" }]
  const [campaigns, setCampaigns] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [current_camp_id, setCurrentCampId] = useState(null);
  const [current_sub_campaign, setCurrentSubCampaign] = useState(null);
  const [not_use_lead_fields, setnot_use_lead_fields] = useState(null);


  const reloadKomponent = () => {
    fetch("https://process-server.videobomb.de/get_all_screenshot_campaigns", {
      headers: {
        'videocomet-token-screenshot-checker': token
      }
    }).then(res => res.json())
      .then(
        (result) => {
          console.log(result)

          if (result.error) {

            if (result?.message === "Signature has expired") logOut();
            setError("get_all_screenshot_campaigns Fehler");
            return;
          };

          setCampaigns(result.all_screenshot_campaigns);
          setCurrentCampId(result.current_camp_id);
          setCurrentSubCampaign(JSON.stringify(result.current_sub_campaign));
          setnot_use_lead_fields(result.not_use_lead_fields);

        },
        (error) => {


          console.log(error)
        }
      )
  }


  useEffect(() => {

    if (token) {
      window.localStorage.setItem("videocomet-screenshot-checker-token", token);
      reloadKomponent();
    }
  }, [token])

  useEffect(() => {
    window.localStorage.getItem("videocomet-screenshot-checker-token") ? setToken(window.localStorage.getItem("videocomet-screenshot-checker-token")) : setToken(null);
  }, [])

  console.log("token", token)

  const logOut = () => {
    setToken(null);
    window.localStorage.removeItem("videocomet-screenshot-checker-token");
    window.location.reload(true);

  }

  return (
    <div className="App" >
      {!token ? <LoginPage setToken={setToken} /> : null}
      {campaigns ? <TabComponent tabs={tabs} campaigns={campaigns} current_camp_id={current_camp_id} current_sub_campaign={current_sub_campaign} token={token} setToken={setToken} reloadKomponent={reloadKomponent} not_use_lead_fields={not_use_lead_fields} logOut={logOut} /> : <div>{error ? error : "Loading..."}</div>}
    </div>
  );
}

export default App;
