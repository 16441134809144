import React, { DragEvent, useState } from "react";

const ImagePreview = ({ src = "" }) => {
  if (src === "") {
    return null;
  }
  return (
    <div className="w-auto mb-8">
      <img className="w-full max-h-[200px] rounded" src={src} alt="" />
    </div>
  );
};

const Dropbox = ({
  path = "",
  createMinified = "0",
  updateMD5 = "0",
  setAlert,
  callBackFunction,
  camp_id,
  screenshot_id,
  lead_id,
  token,
  logOut,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = () => {
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    const formData = new FormData();
    formData.append("image_file", file, file.name);
    formData.append("camp_id", camp_id);
    formData.append("screenshot_id", screenshot_id);
    formData.append("lead_id", lead_id);
    formData.append("file_path", path);
    formData.append("create_min_image", createMinified);
    formData.append("update_md5", updateMD5);

    fetch(
      "https://process-server.videobomb.de/upload_and_replace_screenshot_image",
      {
        method: "POST",
        body: formData,
        noContentType: true,
        headers: {
          "videocomet-token-screenshot-checker": token,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data?.message === "Signature has expired") logOut();
        }
        console.log("Success:", data);
        if (data?.[0]?.lead_id) {
          callBackFunction(true, data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "grey",
        width: "100%",
        height: "150px",
        textAlign: "center",
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <div
        style={{
          pointerEvents: "none",
          fontWeight: "bold",
          color: camp_id !== "551" ? "red" : "white",
          padding: "10px",
        }}
      >
        Drop {isDragging ? "Now" : "Image Here"}
        {camp_id !== "551" ? (
          <div style={{ color: "red", marginTop: "10px" }}>
            Achtung <br />
            Sie sind nicht <br />
            in der Testkampagne. <br />
            Campaign ID: {camp_id}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const Drag = ({
  path = "",
  url = "",
  createMinified = "0",
  updateMD5 = "0",
  showImage = true,
  callBackFunction,
  camp_id,
  screenshot_id,
  lead_id,
  token,
  logOut,
}) => {
  return (
    <>
      <div>
        {showImage && <ImagePreview src={url} />}
        <Dropbox
          path={path}
          createMinified={createMinified}
          updateMD5={updateMD5}
          callBackFunction={callBackFunction}
          camp_id={camp_id}
          screenshot_id={screenshot_id}
          lead_id={lead_id}
          token={token}
          logOut={logOut}
        />
      </div>
    </>
  );
};

export default Drag;
