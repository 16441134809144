import React, { useEffect } from "react";

const PictureModal = ({ isOpen, imgSrc, onClose, imgSmall }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };
    window.addEventListener("keydown", handleEsc);

    if (!isOpen) setPictureLoaded(false);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  const [pictureLoaded, setPictureLoaded] = React.useState(false);

  if (!isOpen) return null;

  return (
    <div
      className={pictureLoaded ? "" : "blurred-img"}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        zIndex: 1000,
      }}
    >
      <img
        id="top-image"
        className={pictureLoaded ? "top-image fade" : "top-image"}
        src={imgSmall}
        style={{ maxWidth: "100%", maxHeight: "100%", position: "absolute" }}
        alt="Modal content"
      />

      <img
        onLoad={() => setPictureLoaded(true)}
        src={imgSrc}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          display: pictureLoaded ? "block" : "none",
          position: "absolute",
        }}
        alt="Modal content"
      />
    </div>
  );
};

export default PictureModal;
