import { useEffect, useState } from "react";
import PictureSlider from "./PictureSlider";
import SortedOut from "./SortedOut";

export default function TabComponent({
  tabs,
  campaigns,
  current_camp_id,
  current_sub_campaign,
  token,
  setToken,
  not_use_lead_fields,
  logOut,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [subCampaignShortcode, setSubCampaignShortcode] =
    useState(current_sub_campaign);
  const [subCampaigns, setSubCampaigns] = useState([]);

  const [imageData, setImageData] = useState(null);

  const [campaignId, setCampaignId] = useState(current_camp_id.toString());
  const [sortedOutImageData, setSortedOutImageData] = useState(null);

  const [keyPressed, setKeyPressed] = useState(null);

  const [hideSortedOut, setHideSortedOut] = useState(null);

  useEffect(() => {
    if (campaigns && campaigns.length > 0 && !campaignId) {
      setCampaignId(campaigns[0].camp_id);
    }
  }, [campaigns]);

  useEffect(() => {
    if (campaignId) {
      fetch(
        "https://process-server.videobomb.de/get_all_screenshot_sub_campaigns/" +
          campaignId,
        {
          headers: {
            "videocomet-token-screenshot-checker": token,
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.error) {
              if (result?.message === "Signature has expired") logOut();
            }
            setSubCampaigns(result);

            if (!subCampaignShortcode && result.length > 0) {
              setSubCampaignShortcode(
                JSON.stringify({
                  screenshot_shortcode: result[0].screenshot_shortcode,
                  width: result[0].width,
                  height: result[0].height,
                })
              );
              document.getElementById("campaignIdInput").value = campaignId;
            }
          },
          (error) => {
            console.log(error);
          }
        );
      setImageData(null);
    }
  }, [campaignId]);

  const get_all_screenshots_details = () => {
    fetch(
      "https://process-server.videobomb.de/get_all_screenshots_details/" +
        campaignId,
      {
        method: "POST",
        body: subCampaignShortcode,
        headers: {
          "videocomet-token-screenshot-checker": token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.error) {
            if (result?.message === "Signature has expired") logOut();
            return;
          }
          console.log("setImageData", result);
          setImageData(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    if (subCampaignShortcode) {
      get_all_screenshots_details();
    }
    get_all_sorted_out_screenshots_details();
  }, [subCampaignShortcode]);

  const get_all_sorted_out_screenshots_details = () => {
    fetch(
      "https://process-server.videobomb.de/get_all_sorted_out_screenshots_details/" +
        campaignId,
      {
        method: "POST",
        body: subCampaignShortcode,
        headers: {
          "videocomet-token-screenshot-checker": token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);

          if (result.error) {
            if (result?.message === "Signature has expired") logOut();
            setSortedOutImageData(null);
            return;
          }
          console.log("setSortedOutImageData", result);
          setSortedOutImageData(result);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const changeTab = async (index) => {
    if (index === 1) {
      setKeyPressed(null);
      setSortedOutImageData(null);
      get_all_sorted_out_screenshots_details();
    } else {
      setKeyPressed(null);
      setImageData(null);
      get_all_screenshots_details();
    }

    setSelectedTab(index);
  };

  return (
    <div
      tabIndex={-1}
      id="tab-component"
      className="tab-component"
      onKeyUp={(e) => {
        const ctrlKey = e.ctrlKey;
        const key = e.key;
        const keyCode = e.keyCode;
        const altKey = e.altKey;
        if (e.target.id === "sorted_siteurl") return;
        setKeyPressed({ ctrlKey, key, keyCode, altKey });
      }}
    >
      <div className="header-component">
        <h2 className="headline">Headline</h2>
        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          {" "}
          <div>
            <input
              id="campaignIdInput"
              placeholder={campaignId}
              style={{ width: "50px", marginRight: "10px" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  if (
                    !isNaN(e.target.value) &&
                    campaigns.indexOf(
                      campaigns.find(
                        (campaign) =>
                          campaign.camp_id.toString() === e.target.value
                      )
                    ) !== -1
                  ) {
                    setCampaignId(e.target.value);
                    setSubCampaignShortcode(null);
                    setImageData(null);
                  } else {
                    document.getElementById("campaignIdInput").value = "";
                    document.getElementById("campaignIdInput").blur();
                    alert("Bitte eine gültige Kampagnen ID eingeben");
                    setTimeout(() => {
                      try {
                        document.getElementById("campaignIdInput").focus();
                      } catch {}
                    }, 300);
                  }
                }
              }}
            ></input>
            <select
              id="campaignIdSelect"
              onChange={(e) => {
                setCampaignId(e.target.value);
                setSubCampaignShortcode(null);
              }}
              style={{ marginBottom: "0px", marginRight: "10px" }}
              onKeyDown={(e) => {
                if (
                  e.key === "ArrowDown" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "x" ||
                  e.key === "v" ||
                  e.key === "c"
                )
                  e.preventDefault();
              }}
              onKeyUp={(e) => {
                if (
                  e.key === "ArrowDown" ||
                  e.key === "ArrowUp" ||
                  e.key === "ArrowLeft" ||
                  e.key === "ArrowRight" ||
                  e.key === "x" ||
                  e.key === "v" ||
                  e.key === "c"
                )
                  e.preventDefault();
              }}
            >
              {campaigns.map((campaign, index) => {
                if (campaign.camp_id.toString() === campaignId) {
                  return (
                    <option key={index} value={campaign.camp_id} selected>
                      ID: {campaign.camp_id} Checked:
                      {campaign.checked_by_screenshot_controller} Screenshots:
                      {campaign.number_of_screenshots}
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={campaign.camp_id}>
                      ID: {campaign.camp_id} Checked:
                      {campaign.checked_by_screenshot_controller} Screenshots:
                      {campaign.number_of_screenshots}
                    </option>
                  );
                }
              })}
            </select>
            <select
              id="subCampaignShortcodeSelect"
              style={{ marginBottom: "0px" }}
              onChange={(e) => {
                setSubCampaignShortcode(e.target.value);
              }}
            >
              {subCampaigns.map((campaign, index) => {
                if (
                  JSON.stringify({
                    screenshot_shortcode: campaign.screenshot_shortcode,
                    width: campaign.width,
                    height: campaign.height,
                  }) === subCampaignShortcode
                ) {
                  return (
                    <option
                      key={index}
                      value={JSON.stringify({
                        screenshot_shortcode: campaign.screenshot_shortcode,
                        width: campaign.width,
                        height: campaign.height,
                      })}
                      selected
                    >
                      height: {campaign.height}
                      width:
                      {campaign.width}
                      Shortcode:
                      {campaign.screenshot_shortcode} Screenshots:
                      {campaign.number_of_screenshots}
                      Checked:
                      {campaign.total_checked_by_screenshot_controller}
                      Modified:
                      {campaign.total_modified_by_screenshot_controller}
                    </option>
                  );
                } else {
                  return (
                    <option
                      key={index}
                      value={JSON.stringify({
                        screenshot_shortcode: campaign.screenshot_shortcode,
                        width: campaign.width,
                        height: campaign.height,
                      })}
                    >
                      height: {campaign.height}
                      width:
                      {campaign.width}
                      Shortcode:
                      {campaign.screenshot_shortcode} Screenshots:
                      {campaign.number_of_screenshots}
                      Checked:
                      {campaign.total_checked_by_screenshot_controller}
                      Modified:
                      {campaign.total_modified_by_screenshot_controller}
                    </option>
                  );
                }
              })}
            </select>
          </div>
          <button
            onClick={() => {
              setToken(null);
              window.localStorage.removeItem(
                "videocomet-screenshot-checker-token"
              );
            }}
          >
            Logout
          </button>{" "}
        </div>
      </div>

      <div className={imageData ? "tabs-header " : "tabs-header blur"}>
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                changeTab(index);
              }}
              className={selectedTab === index ? "activ-tab" : "tab"}
            >
              {tab.name}
            </div>
          );
        })}
      </div>
      <div
        className={
          imageData ? "tab-content-container" : "tab-content-container blur"
        }
        onClick={() => {
          if (!imageData) {
            alert("Please select a sub campaign");
          }
        }}
      >
        <div>
          <h2 className="">{tabs[selectedTab].name}</h2>{" "}
          {selectedTab === 0 && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setHideSortedOut(!hideSortedOut);
              }}
            >
              {hideSortedOut
                ? "Aussortierte einblenden"
                : "Aussortierte ausblenden"}
            </div>
          )}
        </div>

        {selectedTab === 1 ? (
          sortedOutImageData ? (
            <SortedOut
              imageData={sortedOutImageData}
              campaignId={campaignId}
              datenLaden={get_all_sorted_out_screenshots_details}
              token={token}
              keyPressed={keyPressed}
              not_use_lead_fields={not_use_lead_fields}
              logOut={logOut}
            />
          ) : (
            <div>Daten werden geladen ... </div>
          )
        ) : imageData ? (
          <PictureSlider
            imageData={imageData}
            campaignId={campaignId}
            keyPressed={keyPressed}
            logOut={logOut}
            hideSortedOut={hideSortedOut}
          />
        ) : null}
      </div>
    </div>
  );
}
