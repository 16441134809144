import React from "react";
import PictureSliderSortedOutLeft from "./PictureSliderSortedOutLeft";

export default function SortedOut({
  imageData,
  campaignId,
  datenLaden,
  token,
  keyPressed,
  not_use_lead_fields,
  logOut,
}) {
  return (
    <div style={{ height: "calc( 100% - 40px )" }}>
      {imageData?.length > 0 ? (
        <PictureSliderSortedOutLeft
          imageData={imageData}
          campaignId={campaignId}
          datenLaden={datenLaden}
          token={token}
          keyPressed={keyPressed}
          not_use_lead_fields={not_use_lead_fields}
          logOut={logOut}
        />
      ) : (
        <div>Keine Bilder vorhanden</div>
      )}
    </div>
  );
}
