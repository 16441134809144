import React, { useEffect, useState, useRef } from "react";
import PictureSliderSortedOutBottom from "./PictureSliderSortedOutBottom";
import Drag from "./Dropbox";
import PictureModal from "./PictureModal";

export default function PictureSliderSortedOutLeft({
  imageData,
  campaignId,
  datenLaden,
  token,
  keyPressed,
  not_use_lead_fields,
  logOut,
}) {
  const [imageId, setImageId] = useState(null);
  const [sortedOut, setSortedOut] = useState(false);

  const [bigImageUrl, setBigImageUrl] = useState(null);
  const [toggle, setToggle] = useState(false);

  const campaignIdRef = useRef(campaignId);
  const [activeButton, setActiveButton] = useState(null);

  const [lastSortedOut, setLastSortedOut] = useState([]);
  const [reloaded, setReloaded] = useState(false);
  const [urlInput, setUrlInput] = useState("");

  const CustomCheckbox = ({ checked }) => {
    return (
      <div
        className="custom-checkbox"
        style={{
          display: "grid",
          placeContent: "center",
          border: "1px solid #5B6670",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
      >
        {checked && "X"}
      </div>
    );
  };

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (imageId != null) {
      setBigImageUrl({
        vc_screenshot_path_compressed:
          imageData[imageId]?.screenshot_compressed_url,
        vc_screenshot_download_url: imageData[imageId]?.screenshot_big_url,
      });

      setUrlInput(imageData[imageId]?.url);

      if (imageData[imageId]?.not_use_lead === 1) {
        const index = not_use_lead_fields.findIndex((e) => {
          return imageData[imageId]?.not_use_lead_id_reason === e.shortcode;
        });
        if (index !== -1) {
          setActiveButton(index);
        } else {
          alert("not_use_lead_fields Shortcode nicht gefunden");
          setActiveButton(null);
        }
      } else {
        setActiveButton(null);
      }
    }
  }, [imageId, toggle, imageData]);

  useEffect(() => {
    if (imageData && imageData.length > 0 && !reloaded) {
      setImageId(0);
      setToggle(!toggle);
    }
    if (reloaded) {
      setReloaded(false);
    }
  }, [imageData]);

  const getImageElements = (start, end) => {
    const array = [];

    for (let i = start; i < end; i++) {
      if (!imageData?.[i]) continue;
      array.push(
        <div
          key={i}
          onClick={() => {
            setImageId(i);
          }}
          className={imageId === i ? "image-selected " : "not-active-image "}
        >
          <img
            src={imageData?.[i].screenshot_url}
            alt="small"
            className={imageData?.[i]?.sortedOut ? "sorted-out" : ""}
            style={{
              maxWidth: "90%",

              objectFit: "scale-down",
              outline: "2px solid #cccccc",
            }}
          />
        </div>
      );
    }

    return array;
  };

  const createImageOutput = () => {
    if (imageData && imageData.length > 0) {
      if (imageId - 2 <= 0 && imageId + 5 < imageData.length) {
        return getImageElements(0, 5);
      }

      if (imageId - 2 <= 0 && imageId + 5 >= imageData.length) {
        return getImageElements(0, imageData.length);
      }

      if (imageId + 1 >= imageData.length && imageId - 5 >= 0) {
        return getImageElements(imageId - 4, imageData.length);
      }
      if (imageId + 2 >= imageData.length && imageId - 4 >= 0) {
        return getImageElements(imageId - 3, imageData.length);
      }
      if (imageId + 3 >= imageData.length && imageId - 3 >= 0) {
        return getImageElements(imageId - 2, imageData.length);
      }

      return getImageElements(imageId - 2, imageId + 3);
    }
    return [];
  };

  const imageAusgabe = createImageOutput();

  /*   const getUploadParams = () => {
    return {
      url: "https://process-server.videobomb.de/upload_and_replace_screenshot_image",
      headers: {},
      
    };
  }; */

  const changeUrlandOpen = (e) => {
    const newUrl = urlInput
      .replace("https://", "")
      .replace("http://", "")
      .replace("www.", "");
    if (e.target.id === "ws") {
      //open with no refferer in new tab
      window.open("https://www." + newUrl, "_blank", "noopener,noreferrer");
      return;
    }
    if (e.target.id === "s") {
      //open with no refferer in new tab
      window.open("https://" + newUrl, "_blank", "noopener,noreferrer");
      return;
    }
    if (e.target.id === "wh") {
      //open with no refferer in new tab
      window.open("http://www." + newUrl, "_blank", "noopener,noreferrer");
      return;
    }
    if (e.target.id === "h") {
      //open with no refferer in new tab
      window.open("http://" + newUrl, "_blank", "noopener,noreferrer");
      return;
    }
  };

  const set_screenshot_sorted_out = () => {
    fetch("https://process-server.videobomb.de/set_screenshot_sorted_out", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        screenshot_id: imageData[imageId].screenshot_id,
        lead_id: imageData[imageId].lead_id,
        camp_id: campaignId,
        sorted_out: 0,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data?.message === "Signature has expired") logOut();
        }
        console.log("Success:", data);
        setReloaded(true);
        datenLaden();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const set_screenshot_not_use_lead_id_reason = (index) => {
    if (index >= not_use_lead_fields.length || index === null) return;

    const currentIndex = not_use_lead_fields.findIndex((e) => {
      return imageData[imageId]?.not_use_lead_id_reason === e.shortcode;
    });

    let not_use_lead = 0;
    if (currentIndex === index) {
      not_use_lead = 0;
    } else {
      not_use_lead = 1;
    }

    fetch("https://process-server.videobomb.de/set_screenshot_sorted_out", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        screenshot_id: imageData[imageId].screenshot_id,
        lead_id: imageData[imageId].lead_id,
        camp_id: campaignId,
        sorted_out: 1,
        not_use_lead: not_use_lead,
        not_use_lead_id_reason:
          not_use_lead === 1 ? not_use_lead_fields[index].shortcode : null,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data?.message === "Signature has expired") logOut();
        }
        console.log("Success:", data);
        setReloaded(true);
        datenLaden();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const set_screenshot_sorted_out_undo = () => {
    fetch("https://process-server.videobomb.de/set_screenshot_sorted_out", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(lastSortedOut[lastSortedOut.length - 1]),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data?.message === "Signature has expired") logOut();
        }
        const lastSortedOutCopy = [...lastSortedOut];
        lastSortedOutCopy.splice(lastSortedOutCopy.length - 1, 1);

        setLastSortedOut(lastSortedOutCopy);
        setReloaded(true);
        datenLaden();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (keyPressed === null) return;
    if (keyPressed.key === "ArrowUp") {
      if (imageId > 0) {
        setImageId(imageId - 1);
      } else {
        setImageId(imageData.length - 1);
      }
      return;
    }
    if (keyPressed.key === "ArrowDown") {
      if (imageId < imageData.length - 1) {
        setImageId(imageId + 1);
      } else {
        setImageId(0);
      }
      return;
    }

    if (keyPressed.keyCode === 32 && keyPressed.ctrlKey) {
      set_screenshot_sorted_out();
      const newUndoList = [
        ...lastSortedOut,
        {
          screenshot_id: imageData[imageId].screenshot_id,
          lead_id: imageData[imageId].lead_id,
          camp_id: campaignId,
          sorted_out: 1,
        },
      ];
      setLastSortedOut(newUndoList);
    }

    if (
      keyPressed.altKey &&
      !isNaN(parseInt(keyPressed.key)) &&
      parseInt(keyPressed.key) <= not_use_lead_fields.length
    ) {
      set_screenshot_not_use_lead_id_reason(parseInt(keyPressed.key) - 1);
    }

    if (keyPressed.key === "z" && keyPressed.ctrlKey) {
      set_screenshot_sorted_out_undo();
    }
  }, [keyPressed]);

  console.log("not_use_lead_fields", not_use_lead_fields);

  if (imageData?.[imageId] === undefined) {
    if (imageData?.[imageId - 1]) {
      setImageId(imageId - 1);
    } else {
      setImageId(0);
    }
    return <div>Loading...</div>;
  }

  console.log("imageData", imageData);

  console.log(imageId, "imageData[imageId].screenshot_id");

  return (
    <div id="pictureslider" style={{ display: "flex", height: "100%" }}>
      <div
        className=""
        id="pictureslider-image-slider"
        style={{
          maxWidth: "20%",
          padding: "20px",
          borderRight: "1px solid black",
          height: "calc(100% - 40px)",
          overflow: "hidden",
          flexGrow: 0,
        }}
      >
        <div
          className="pictureslider-image-slider-content-sorted-out-left"
          id="pictureslider-image-slider-content"
          style={{ height: "100%", overflow: "auto" }}
        >
          {imageAusgabe}
        </div>
      </div>
      <div
        className=""
        style={{
          maxWidth: "60%",
          padding: "20px",
          maxHeight: "100%",
          flexGrow: 0,
        }}
      >
        <div className="">
          <div
            onClick={() => {
              const id = prompt(
                "Zu welchem Bild möchten Sie springen?",
                imageId + 1
              );
              if (
                id != null &&
                id !== "" &&
                !isNaN(id) &&
                id >= 1 &&
                id <= imageData.length
              ) {
                setImageId(parseInt(id) - 1);
              }
            }}
          >
            {imageId + 1} / {imageData.length}
          </div>
        </div>
        <div
          className={imageData[imageId]?.sortedOut ? " red-border" : ""}
          style={{ height: "100%", textAlign: "center" }}
        >
          {imageId != null && (
            <img
              onClick={openModal}
              src={
                bigImageUrl?.vc_screenshot_path_compressed
                  ? bigImageUrl?.vc_screenshot_path_compressed
                  : bigImageUrl?.vc_screenshot_download_url
              }
              alt="big"
              style={{
                maxWidth: "100%",
                height: "60%",
                objectFit: "scale-down",
                outline: "2px solid #cccccc",
              }}
            />
          )}
          <div style={{ height: "38%" }}>
            {imageData?.[imageId]?.screenshot_overwrite_history.length > 0 && (
              <PictureSliderSortedOutBottom
                imageData={imageData[imageId].screenshot_overwrite_history}
                campaignId={campaignId}
                setBigImageUrl={setBigImageUrl}
                keyPressed={keyPressed}
              ></PictureSliderSortedOutBottom>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          maxWidth: "20%",
          minWidth: "20%",
          padding: "20px",
          borderLeft: "1px solid black",
          maxHeight: "100%",
          flexGrow: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div style={{ marginBottom: "50px" }}>
            {lastSortedOut.length > 0 && (
              <>
                <button
                  onClick={() => {
                    set_screenshot_sorted_out_undo();
                  }}
                  style={{
                    backgroundColor: "#007BFF", // Hintergrundfarbe des Buttons
                    color: "white", // Textfarbe
                    padding: "10px 20px", // Polsterung um den Text
                    fontSize: "16px", // Schriftgröße
                    border: "none", // Kein Rand
                    borderRadius: "5px", // Abgerundete Ecken
                    cursor: "pointer", // Cursor-Stil beim Überfahren mit der Maus
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                    marginBottom: "15px",
                    marginRight: "15px",
                  }}
                >
                  Undo
                </button>

                {lastSortedOut.length}
              </>
            )}
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                marginBottom: "15px",
              }}
              onClick={() => {
                set_screenshot_sorted_out();
                const newUndoList = [
                  ...lastSortedOut,
                  {
                    screenshot_id: imageData[imageId].screenshot_id,
                    lead_id: imageData[imageId].lead_id,
                    camp_id: campaignId,
                    sorted_out: 1,
                  },
                ];

                setLastSortedOut(newUndoList);
              }}
            >
              <CustomCheckbox checked={true} /> Aussortiert
              <div style={{ fontWeight: "800" }}>
                Reason:{" "}
                {imageData[imageId]?.sorted_out_reason
                  ? imageData[imageId]?.sorted_out_reason
                  : ""}
              </div>
            </div>
            <div>Lead Id: {imageData?.[imageId].lead_id}</div>
            <div> Screenshot Id: {imageData?.[imageId].screenshot_id}</div>
          </div>
          <div>
            <div style={{ display: "flex", gap: "5px", marginBottom: "10px" }}>
              <input
                id="sorted_siteurl"
                value={urlInput}
                onChange={(e) => {
                  setUrlInput(e.target.value);
                }}
                style={{ flexBasis: "80%" }}
              ></input>{" "}
              <img
                src="s"
                alt="Save"
                style={{ cursor: "pointer", textDecoration: "underline" }}
              />{" "}
              <div
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  window.open(urlInput, "_blank", "noopener,noreferrer");
                }}
              >
                Open
              </div>
            </div>
            <div style={{ display: "flex", gap: "5px" }}>
              <button
                id="ws"
                onClick={changeUrlandOpen}
                style={{ padding: "5px 10px" }}
              >
                ws
              </button>
              <button
                id="s"
                onClick={changeUrlandOpen}
                style={{ padding: "5px 10px" }}
              >
                s
              </button>
              <button
                id="wh"
                onClick={changeUrlandOpen}
                style={{ padding: "5px 10px" }}
              >
                wh
              </button>
              <button
                id="h"
                onClick={changeUrlandOpen}
                style={{ padding: "5px 10px" }}
              >
                h
              </button>
            </div>
          </div>
        </div>
        <div
          className="inactive-button-container"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            alignItems: "center",
            marginTop: "20px",
          }}
          onClick={(e) => {
            e.stopPropagation();

            set_screenshot_not_use_lead_id_reason(parseInt(e.target.id));
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              width: "80%",
            }}
          >
            {not_use_lead_fields?.map((field, index) => {
              return (
                <button
                  id={index}
                  className={
                    activeButton === index ? "active-button" : "inactive-button"
                  }
                >
                  {field.value}
                </button>
              );
            })}
          </div>
        </div>
        {campaignId &&
          imageData[imageId]?.screenshot_id &&
          imageData[imageId]?.lead_id && (
            <Drag
              path={""}
              createMinified={"1"}
              updateMD5={"1"}
              callBackFunction={(done, data) => {
                if (done) {
                  setReloaded(true);
                  datenLaden();
                }
              }}
              camp_id={campaignId}
              screenshot_id={imageData[imageId]?.screenshot_id}
              lead_id={imageData[imageId]?.lead_id}
              token={token}
              logOut={logOut}
            />
          )}
      </div>
      <PictureModal
        isOpen={isOpen}
        imgSrc={bigImageUrl?.vc_screenshot_download_url}
        onClose={closeModal}
        imgSmall={bigImageUrl?.vc_screenshot_path_compressed}
      />
    </div>
  );
}
